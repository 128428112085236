import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import cn from "@/libs/cn";
import Image from "@/components/basic/Image";

import styles from "./styles.module.scss";

const renderArrowPrev = (
  onClickHandler: (e: any) => void,
  hasPrev: boolean,
  label: string
) => {
  return (
    <div className={cn(styles.arrow, styles.prev)} onClick={onClickHandler}>
      <Image
        src="/assets/arrow-circle-prev.svg"
        alt="arrow prevision"
        width={40}
        height={40}
      />
    </div>
  );
};

const renderArrowNext = (
  onClickHandler: (e: any) => void,
  hasPrev: boolean,
  label: string
) => {
  return (
    <div className={cn(styles.arrow, styles.next)} onClick={onClickHandler}>
      <Image
        src="/assets/arrow-circle-next.svg"
        alt="arrow prevision"
        width={40}
        height={40}
      />
    </div>
  );
};

interface CustomCarousel {
  renderItem: (item: any) => JSX.Element;
  data: any[];
}

class CustomCarousel extends Component {
  render() {
    const { renderItem, data } = this.props as CustomCarousel;
    const renderEl = renderItem || function () {};

    return (
      <div className={styles.Carousel}>
        {/* @ts-ignore */}
        <Carousel
          autoPlay
          infiniteLoop
          interval={5000}
          showStatus={false}
          transitionTime={500}
          dynamicHeight={true}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
          showThumbs={false}
        >
          {(data || []).map(renderEl)}
        </Carousel>
      </div>
    );
  }
}

export default CustomCarousel;
