import CustomCarousel from "@/components/Carousel";
import { useDataPublicBanners } from "@/modules/public/hooks/useData";
import { useCallback } from "react";

import styles from "./Banner.module.scss";

const Banner = () => {
  const { data } = useDataPublicBanners();

  const renderItem = useCallback(
    (item: any, idx: number) => (
      <a key={idx} href={`${item.link}`} target="_blank" rel="noreferrer">
        <img src={item.src} alt={`slide ${idx}`} className={styles.img} />
      </a>
    ),
    []
  );

  return (
    <div className={styles.Banner}>
      {/* @ts-ignore */}
      {!!data?.length && <CustomCarousel data={data} renderItem={renderItem} />}
    </div>
  );
};

export default Banner;
